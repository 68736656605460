/* Base styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
  line-height: 1.5;
  font-size: 16px;
}

/* App container - grid layout with sidebar */
.app-container {
  display: grid;
  /* Increase the right column proportion */
  grid-template-columns: 200px minmax(0, 1fr);
  min-height: 100vh;
}

/* Links - blue as requested */
a {
  color: #0066cc;
  text-decoration: underline;
  text-underline-offset: 2px;
}

a:hover {
  text-decoration-thickness: 2px;
}

/* Sidebar */
.sidebar {
  border-right: 1px solid #000;
  padding: 2rem 1rem;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logo {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: auto;
}

.nav-item {
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
  font-size: 0.9rem;
}

.nav-item:hover,
.nav-item.active {
  text-decoration: underline;
}

.nav-item.active {
  font-weight: 500;
}

.sidebar-footer {
  margin-top: 2rem;
  font-size: 0.8rem;
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 1.5rem;
}

.social-links a {
  color: #000;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}

.copyright {
  color: #777;
}

/* Main content area */
.main-content {
  padding: 0 2rem 2rem;
  /* Remove the max-width constraint to allow full width */
  max-width: none;
  width: 90%;
  position: relative;
}

/* Sticky header */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 1rem 0;
  z-index: 10;
  border-bottom: 1px solid #000;
}

.section-indicator {
  font-weight: 700;
  font-size: 1.2rem;
}

/* Header section */
.header {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.header-content {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.header-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 1px solid #000;
}

.header-text {
  flex: 1;
}


/* YouTube video card styling */
.card-video {
  height: 180px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #eee;
}

.video-thumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
}

.video-thumbnail:hover {
  transform: scale(1.05);
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.2s ease;
}

.video-thumbnail:hover .play-button {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
}

/* Plain HTML-style article list */
.plain-article-list {
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.plain-article-item {
  padding: 0.7rem 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.plain-link {
  color: #0000EE;
  /* Traditional HTML link blue */
  text-decoration: underline;
  font-family: Times New Roman, serif;
  /* Traditional font */
  font-size: 1rem;
}

.plain-link:visited {
  color: #551A8B;
  /* Traditional visited link color */
}

.plain-date {
  font-size: 0.9rem;
  color: #666;
  font-family: Times New Roman, serif;
  margin-left: 1rem;
}

@media (max-width: 600px) {
  .plain-article-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .plain-date {
    margin-left: 0;
    margin-top: 0.2rem;
  }
}



/* ArticlePage.css */
.article-page {
  max-width: 700px;
  margin: 0 auto;
  padding: 60px 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.article-header {
  margin-bottom: 40px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.article-header h1 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 12px;
  letter-spacing: -0.01em;
  line-height: 1.2;
}

.article-meta {
  display: flex;
  gap: 15px;
  color: #666;
  font-size: 0.9rem;
}

.article-category {
  padding: 2px 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #555;
}

.article-content {
  font-size: 1.05rem;
  line-height: 1.8;
}

.article-lead {
  font-size: 1.15rem;
  line-height: 1.7;
  color: #444;
  margin-bottom: 30px;
}

.article-content h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 35px 0 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.article-content p {
  margin-bottom: 24px;
}

.article-navigation {
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.back-link {
  display: inline-block;
  color: #0000EE;
  text-decoration: none;
  font-family: Times New Roman, serif;
  font-size: 1rem;
  padding: 6px 0;
  position: relative;
}

.back-link:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .article-page {
    padding: 40px 20px;
  }

  .article-header h1 {
    font-size: 1.8rem;
  }

  .article-content {
    font-size: 1rem;
  }
}

/* Print styles */
@media print {
  .article-page {
    max-width: none;
    padding: 0;
  }

  .article-navigation {
    display: none;
  }
}


h1 {
  font-size: 1.8rem;
  margin: 0 0 1rem 0;
  font-weight: 700;
}

.bio {
  margin-bottom: 1rem;
}

.location {
  margin: 0;
}

.philosophy,
.interests {
  margin-bottom: 1rem;
}

/* Section styling */
.section {
  padding-top: 1rem;
}

.section-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

.subsection-title {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 2.5rem 0 1.5rem;
  text-transform: uppercase;
}

/* Current list */
.current-list {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.current-list li {
  margin-bottom: 0.8rem;
}

/* Cards layout */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.card {
  border: 1px solid #000;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

/* Pastel hover effects */
.card.pink-hover:hover {
  background-color: #ffebee;
  /* Pastel pink */
}

.card.pink2-hover:hover {
  background-color: #f7ddfe;
  /* Pastel pink */
}
.card.yellow-hover:hover {
  background-color: #fffde7;
  /* Pastel yellow */
}

.card.purple-hover:hover {
  background-color: #f3e5f5;
  /* Pastel purple */
}

.card-image {
  height: 180px;
  background-color: #f5f5f5;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .card-image img {
  transform: scale(1.05);
}

.card-content {
  padding: 1rem;
}

.card-title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
}

.card-description {
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
}

.card-date {
  font-size: 0.8rem;
  color: #666;
  margin: 0.5rem 0 0 0;
}

.card-link {
  margin: 0.5rem 0 0 0;
  font-size: 0.9rem;
}

/* Travel section */
.travel-intro {
  margin-bottom: 1.5rem;
}

/* Footer */
.footer {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid #000;
  text-align: center;
}

.footer .copyright {
  font-size: 0.8rem;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-container {
    grid-template-columns: 1fr;
  }

  .sidebar {
    display: none;
  }

  .header-content {
    flex-direction: column;
    gap: 1rem;
  }

  .header-image img {
    width: 120px;
    height: 120px;
  }

  .cards-container {
    grid-template-columns: 1fr;
  }
}

/* Mobile menu button - for future implementation */
.mobile-menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    background: none;
    border: 1px solid #000;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

/* Print styles */
@media print {
  .app-container {
    display: block;
  }

  .sidebar,
  .sticky-header {
    display: none;
  }

    .main-content {
      padding: 0 1rem 2rem;
    }

  .card {
    break-inside: avoid;
  }
}